<template>
    <div>
        <FilterModal
            @reset="resetFilter"
            @filter="getFilterData"
            :isLoading="isLoading"
        >
            <template v-slot:filters>
                <TitleTypeAccountFilter
                    ref="titleTypeAccountFilter"
                    v-model:titleType="filterPayload.titleType"
                    v-model:manCode="filterPayload.manCode"
                />
                <Dropdown
                    name="dataType"
                    :required="true"
                    :title="filterTitles.dataType"
                    :options="filterOptions.dataType"
                    v-model="filterPayload.dataType"
                />
                <Dropdown
                    name="relationMode"
                    :required="true"
                    :title="filterTitles.relationMode"
                    :options="filterOptions.relationMode"
                    v-model="filterPayload.relationMode"
                />
                <Dropdown
                    name="supplierCode"
                    placeholder="全部"
                    :searchable="true"
                    :title="filterTitles.supplierCode"
                    :options="filterOptions.supplierCode"
                    v-model="filterPayload.supplierCode"
                />
                <Dropdown
                    name="payWay"
                    placeholder="全部"
                    :title="filterTitles.payWay"
                    :options="filterOptions.payWay"
                    v-model="filterPayload.payWay"
                />
                <DateRangeTextbox
                    v-if="showDateFilter.receive"
                    startName="receiveDateStart"
                    endName="receiveDateEnd"
                    max="today"
                    min="default"
                    :required="true"
                    :tipObj="dateFilterTips.receive"
                    :hasLimit="true"
                    :limitDays="limitDays"
                    title="受理日"
                    v-model:startDate="filterPayload.receiveDateStart"
                    v-model:endDate="filterPayload.receiveDateEnd"
                />
                <DateRangeTextbox
                    v-if="showDateFilter.effect"
                    startName="effectDateStart"
                    endName="effectDateEnd"
                    max="today"
                    min="default"
                    :hasLimit="true"
                    :tipObj="dateFilterTips.effect"
                    title="生效日"
                    v-model:startDate="filterPayload.effectDateStart"
                    v-model:endDate="filterPayload.effectDateEnd"
                />
                <DateRangeTextbox
                    v-if="showDateFilter.commit"
                    startName="commitDateStart"
                    endName="commitDateEnd"
                    max="today"
                    min="default"
                    :required="true"
                    :tipObj="dateFilterTips.commit"
                    :isOnlyMonth="true"
                    :hasLimit="true"
                    :limitDays="limitDays"
                    title="核實月"
                    v-model:startDate="filterPayload.commitDateStart"
                    v-model:endDate="filterPayload.commitDateEnd"
                />
                <Dropdown
                    v-if="filterPayload.dataType === 1"
                    name="isAudit"
                    :required="true"
                    :title="filterTitles.isAudit"
                    :options="filterOptions.isAudit"
                    v-model="filterPayload.isAudit"
                />
                <Dropdown
                    name="jobTypes"
                    :title="filterTitles.jobTypes"
                    :options="filterOptions.jobTypes"
                    mode="checkbox"
                    :isCheckboxNum="true"
                    v-model="filterPayload.jobTypes"
                />
                <Dropdown
                    name="customType"
                    tips="顯示業績為原始業績"
                    :required="true"
                    :title="filterTitles.customType"
                    :options="filterOptions.customType"
                    v-model="filterPayload.customType"
                />
            </template>
        </FilterModal>
    </div>
</template>
<script>
import _ from 'lodash'
import Dropdown from '@/components/Dropdown.vue'
import DateRangeTextbox from '@/components/DateRangeTextbox.vue'
import FilterModal from '@/containers/FilterModal.vue'
import TitleTypeAccountFilter from '@/containers/TitleTypeAccountFilter.vue'
import { getPerformanceCodesAPI } from '@/assets/javascripts/api'
import { performanceFilterTitles } from '@/assets/javascripts/performance/setting'
import filterDefault from '@/assets/json/filterDefault.json'

export default {
    name: 'PerformanceFilter',
    emits: [
        'show-data-table',
        'update:filterPayloadProp',
        'update:filterLogProp'
    ],
    components: {
        Dropdown,
        FilterModal,
        TitleTypeAccountFilter,
        DateRangeTextbox
    },
    props: {
        filterPayloadProp: {
            type: Object,
            default: function () {
                return {
                    manCode: '',
                    endDate: '',
                    dataType: '',
                    relationMode: '',
                    bgCode: '',
                    customType: '',
                    jobTypes: []
                }
            }
        },
        filterLogProp: {
            type: Object,
            default: function () {
                return {}
            }
        },
        listData: {
            type: Array,
            default: function () {
                return []
            }
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getFilterData: function () {
            /*
             ** 若送api的payload有異動才打API
             */
            if (
                !this.$isSameValue(
                    this.perviousFilterPayload,
                    this.filterPayload
                )
            ) {
                this.perviousFilterPayload = this.$cloneDeepValue(
                    this.filterPayload
                )
                this.$emit('show-data-table')
                this.filterLog = this.$getFilterLog(this.filterPayload)
                this.filterLog.commitDateStart.label = this.startDateLabel
                this.filterLog.commitDateEnd.label = this.endDateLabel
            }
            this.hasCrossFilter = false
        },
        resetFilter: function () {
            this.filterPayload = {
                manCode: this.filterPayload?.manCode || '',
                receiveDateStart: this.$formatDate(
                    this.$getFirstDate(this.$getDate())
                ),
                receiveDateEnd: this.$formatDate(this.$getDate()),
                commitDateStart: this.$formatDate(
                    this.$getFirstDate(this.$getDate(0, -2))
                ),
                commitDateEnd: this.commitMaxDateDefault,
                jobTypes: this.filterOptions?.jobTypes?.map(
                    (data) => data.value
                ),
                ...filterDefault.performance
            }
            // includes mType, manCode(payload)
            this.$refs.titleTypeAccountFilter.resetFilter()

            this.dropdownLevelValue = ''
            this.filterPayload = this.$getStorePayload(this.filterPayload)
        },
        getDropdownOptions: async function () {
            this.$showLoading()
            try {
                const res = await getPerformanceCodesAPI()
                this.dropdownOptions = this.$transformInputType(res.data)
            } catch (error) {
                this.dropdownOptions = {}
                this.$showErrorMessage(
                    '載入時出現錯誤，請稍後再試',
                    '若持續出現此問題，請聯繫資訊客服'
                )
            } finally {
                this.$hideLoading()
            }
        }
    },
    computed: {
        filterOptions: function () {
            return {
                dataType: _.uniqBy(
                    this.dropdownOptions.dataType?.map((data) => {
                        return {
                            label: data.value,
                            value: data.key
                        }
                    }),
                    (option) => option.value
                ),
                relationMode: _.uniqBy(
                    this.dropdownOptions.relationMode?.map((data) => {
                        return {
                            label: data.value,
                            value: data.key
                        }
                    }),
                    (option) => option.value
                ).reverse(),
                supplierCode: _.uniqBy(
                    this.dropdownOptions.supplier?.map((data) => {
                        return {
                            label: data.value,
                            value: parseInt(data.key)
                        }
                    }),
                    (option) => option.value
                ).sort((a, b) => a?.label?.localeCompare(b.label)),
                payWay: this.allOption.concat(
                    _.uniqBy(
                        this.dropdownOptions.payWay?.map((data) => {
                            return {
                                label: data.value,
                                value: data.key
                            }
                        }),
                        (option) => option.value
                    )
                ),
                isAudit: this.allOption.concat([
                    {
                        label: '已審',
                        value: true
                    },
                    {
                        label: '未審',
                        value: false
                    }
                ]),
                customType: this.allOption.concat(
                    _.uniqBy(
                        this.dropdownOptions.customType?.map((data) => {
                            return {
                                label: data.value,
                                value: data.key
                            }
                        }),
                        (option) => option.value
                    )
                ),
                jobTypes: _.uniqBy(
                    this.dropdownOptions.jobType?.map((data) => {
                        return {
                            label: data.value,
                            value: data.key
                        }
                    }),
                    (option) => option.value
                )
            }
        },
        commitMaxDateDefault: function () {
            const lastFirstDate = this.$getFirstDate(this.$getDate())
            return this.$formatDate(this.$getDate(-1, 0, 0, lastFirstDate))
        },
        startDateLabel: function () {
            /* 核實 查詢日改查詢月，值需把日trim掉 */
            const date = this.filterPayload.commitDateStart
            const period = date?.slice(0, 7)
            return period
        },
        endDateLabel: function () {
            /* 核實 查詢日改查詢月，值需把日trim掉 */
            const date = this.filterPayload.commitDateEnd
            const period = date?.slice(0, 7)
            return period
        },
        showDateFilter: function () {
            const dataType1 = this.filterPayload.dataType === 1
            // const isSelectedReported = this.filterPayload.dataType === 1
            const dataType2 = this.filterPayload.dataType === 2
            // const isSelectedVerify = this.filterPayload.dataType === 2
            const dataType3 = this.filterPayload.dataType === 3
            // const isSelectedBoth = this.filterPayload.dataType === 3

            return {
                receive: dataType1 || dataType3,
                effect: dataType3,
                commit: dataType2 || dataType3
            }
        },
        dateFilterTips: function () {
            const tip1 = `為提供較佳的效能體驗，供查詢的資料區間僅限12個月`
            const effectTips = '生效日(起)、生效日(迄)須同時填寫，或同時清除'
            const Tips = {
                receive: { start: tip1, end: tip1 },
                effect: { start: effectTips, end: effectTips },
                commit: { start: tip1, end: tip1 }
            }
            if (this.filterPayload.dataType === 3)
                Tips.commit = { start: '', end: '' }

            return Tips
        },
        limitDays: function () {
            return { year: 1 }
        }
    },
    watch: {
        filterPayload: {
            handler() {
                if (!this.filterPayload.jobTypes)
                    this.filterPayload.jobTypes = []
                this.$emit('update:filterPayloadProp', this.filterPayload)
            },
            deep: true
        },
        filterLog: {
            handler() {
                this.$emit('update:filterLogProp', this.filterLog)
            },
            deep: true
        },
        'filterPayload.dataType': {
            handler(val) {
                this.$reloadTips()
                if (val !== 1) {
                    this.filterPayload.isAudit = ''
                }
            }
        }
    },
    data() {
        return {
            allOption: [
                {
                    label: '全部',
                    value: ''
                }
            ],
            filterTitles: {},
            dropdownOptions: { relationMode: [], supplier: [], payWay: [] },

            perviousFilterPayload: {},
            filterPayload: this.filterPayloadProp,
            filterLog: this.filterLogProp,
            hasCrossFilter: this.$hasCrossFilter()
        }
    },
    async mounted() {
        this.$nextTick(() => {
            const titleTypeAccountTitles = this.$refs.titleTypeAccountFilter
                .filterTitles
            this.filterTitles = {
                ...titleTypeAccountTitles,
                ...performanceFilterTitles,
                _seq: [
                    ...titleTypeAccountTitles._seq,
                    ...performanceFilterTitles._seq
                ]
            }
        })
        await this.getDropdownOptions()
        await this.resetFilter()
        if (this.hasCrossFilter) await this.getFilterData()
    }
}
</script>
<style lang="scss" scoped>
.effect-date {
    :deep(.required) {
        display: none;
    }
}
</style>
