const performanceFilterTitles = {
    dataType: '業績類別',
    receiveDateStart: '受理日(起)',
    receiveDateEnd: '受理日(迄)',
    effectDateStart: '生效日(起)',
    effectDateEnd: '生效日(迄)',
    commitDateStart: '核實月(起)',
    commitDateEnd: '核實月(迄)',
    relationMode: '組織種類',
    supplierCode: '保險公司',
    payWay: '繳別',
    isAudit: '審核狀態',
    bgName: '營業單位',
    salesName: '個人業務員姓名',
    salesTitle: '職級',
    level: '代數',
    jobTypes: '險種',
    customType: '四贏/特定',
    _seq: [
        'dataType',
        'receiveDateStart',
        'receiveDateEnd',
        'effectDateStart',
        'effectDateEnd',
        'commitDateStart',
        'commitDateEnd',
        'relationMode',
        'supplierCode',
        'payWay',
        'isAudit',
        'jobTypes',
        'customType'
    ]
}

const performanceColumns = {
    serialNumber: {
        label: '編號',
        sortEnabled: false,
        isFilterable: false,
        type: 'number',
        position: 'center',
        total: 'key',
        width: 6,
        widthPx: 50,
        isUnmovable: true
    },
    salesName: {
        label: '業務員姓名',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 14,
        widthPx: 110,
        isUnmovable: true
    },
    salesTitle: {
        label: '職級',
        sortEnabled: true,
        isFilterable: true,
        divider: true,
        type: 'string',
        position: 'center',
        width: 8,
        widthPx: 80,
        isUnmovable: true
    },
    level: {
        label: '代數',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        width: 6,
        widthPx: 60,
        isUnmovable: true
    },

    bgName: {
        label: '營業單位',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 8,
        widthPx: 90
    },
    orgBm: {
        label: '事業部',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 13,
        widthPx: 90
    },
    orgBmName: {
        label: '部主管',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 8,
        widthPx: 75
    },

    apStuName: {
        label: '介紹人',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 12,
        widthPx: 90
    },
    supplier: {
        label: '保險公司',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 13,
        widthPx: 90
    },
    insNo: {
        label: '保單號碼',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 17,
        widthPx: 140
    },
    receiveDate: {
        label: '受理日',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        width: 11,
        widthPx: 100
    },
    effectDate: {
        label: '生效日',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        width: 11,
        widthPx: 100
    },
    insured: {
        label: '被保人',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 9,
        widthPx: 80
    },
    productName: {
        label: '主約名稱',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 17,
        widthPx: 140
    },
    jobTypes: {
        label: '險種',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 7,
        widthPx: 70
    },
    yPeriod: {
        label: '年期',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        width: 5,
        widthPx: 60
    },
    payWay: {
        label: '繳別',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 7,
        widthPx: 60
    },
    currencyText: {
        label: '幣別',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 7,
        widthPx: 60
    },
    isGeneral: {
        label: '一般商品',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 7,
        widthPx: 75
    },
    isFourWin: {
        label: '四贏商品',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 7,
        widthPx: 75
    },
    isSpecial: {
        label: '特定商品',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 7,
        widthPx: 75
    },
    isMobileInsured: {
        label: '行動投保',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 7,
        widthPx: 75
    },
    solicitorOri: {
        label: '招攬人',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        divider: true,
        position: 'center',
        width: 8,
        widthPx: 75
    },
    lifeRfyp: {
        label: `壽險
        實收保費`,
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        width: 11,
        widthPx: 115
    },
    lifeFyb: {
        label: `壽險
        FYC`,
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        width: 11,
        widthPx: 115
    },
    lifeFybRfypRate: {
        label: `壽險
        CP比`,
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'mean',
        meanBy: ['lifeFyb', 'lifeRfyp'],
        unit: '%',
        width: 10,
        widthPx: 100
    },
    lifeFyn: {
        label: `壽險
        件數`,
        sortEnabled: true,
        isFilterable: true,
        divider: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '件',
        width: 9,
        widthPx: 80
    },
    propertyRfyp: {
        label: `產險
        實收保費`,
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        width: 11,
        widthPx: 115
    },
    propertyFyb: {
        label: `產險
        FYC`,
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        width: 11,
        widthPx: 115
    },
    propertyFybRfypRate: {
        label: `產險
        CP比`,
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'mean',
        meanBy: ['propertyFyb', 'propertyRfyp'],
        unit: '%',
        width: 10,
        widthPx: 100
    },
    propertyFyn: {
        label: `產險
        件數`,
        sortEnabled: true,
        isFilterable: true,
        divider: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '件',
        width: 9,
        widthPx: 80
    },
    groupRfyp: {
        label: '團旅平險 實收保費',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        width: 11,
        widthPx: 115
    },
    groupFyb: {
        label: `團旅平險
        FYC`,
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        width: 11,
        widthPx: 115
    },
    groupFybRfypRate: {
        label: '團旅平險 CP比',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'mean',
        meanBy: ['groupFyb', 'groupRfyp'],
        unit: '%',
        width: 10,
        widthPx: 100
    },
    groupFyn: {
        label: '團旅平險 件數',
        sortEnabled: true,
        isFilterable: true,
        divider: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '件',
        width: 9,
        widthPx: 80
    },
    rfyp: {
        label: '實收保費',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        width: 11,
        widthPx: 115
    },
    fyb: {
        label: 'FYC',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '元',
        width: 11,
        widthPx: 115
    },
    fybRfypRate: {
        label: 'CP比',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'mean',
        meanBy: ['fyb', 'rfyp'],
        unit: '%',
        width: 10,
        widthPx: 100
    },
    fyn: {
        label: '件數',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'right',
        total: 'sum',
        unit: '件',
        width: 9,
        widthPx: 80
    },
    count: {
        label: '比率',
        sortEnabled: true,
        isFilterable: true,
        type: 'number',
        position: 'center',
        unit: '%',
        width: 7,
        widthPx: 70
    }
}
export { performanceFilterTitles, performanceColumns }
